<template>
  <div>
    <div class="banner">
      <el-image :src="bannerUrl">
        <div slot="placeholder" class="image-slot">
          <i class="el-icon-loading"></i>图片正在加载
        </div>
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
    </div>
    <div class="contact-content">
      <p class="zh-title">联系我们</p>
      <p class="en-title">CONTACT US</p>
      <div class="contact-text">
        如有任何问题请联系我们，我们7*24小时竭诚为您服务
      </div>
      <div class="flex">
        <div class="strategy-item">
          <img src="../../assets/images/icon/mail@2x.png" alt="">
          <p class="strategy-item-title">邮箱</p>
          <p class="strategy-item-text">yshi@yluxing.com</p>
        </div>
        <div class="strategy-item">
          <img src="../../assets/images/icon/phone@2x.png" alt="">
          <p class="strategy-item-title">电话</p>
          <p class="strategy-item-text">0571-85239098</p>
        </div>
        <div class="strategy-item">
          <img src="../../assets/images/icon/location@2x.png" alt="">
          <p class="strategy-item-title">地址</p>
          <p class="strategy-item-text">浙江省杭州市运河东路378号4幢1608</p>
        </div>
      </div>
      <div class="qrcode-wrapper flex">
        <div class="sub-banner">
          <el-image :src="introImg">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="qrcode-box">
          <img src="../../assets/images/gz_qrcode.png" />
          <p>扫一扫关注我们</p>
        </div>
      </div>
      <div class="address-box sub-banner">
        <el-image :src="addressImg">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
    </div>
  </div>
</template>

<script>
import { getBanner } from './request'
export default {
  data() {
    return {
      bannerUrl: '',
      introImg: '',
      addressImg: ''
    }
  },
  mounted() {
    this.fetchImgUrl()
  },
  methods: {
    async fetchImgUrl() {
      let res = await getBanner({
        type: 'contact',
        provider: 'contact'
      })
      if(res.data.data){
        const imgUrl = JSON.parse(res.data.data)
        this.bannerUrl = imgUrl.contactBanner
        this.introImg = imgUrl.introImg
        this.addressImg = imgUrl.addressImg
      }
    }
  }
}
</script>

<style lang="less" scoped>
.banner{
  margin-bottom: 40px;
  img{
    width: 100%;
  }
}
.contact-content{
  text-align: center;
  font-size: 16px;
  color: #333;
  padding: 0 10%;
  margin-bottom: 30px;
}
.zh-title{
  font-size: 40px;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: 8.33px;
  text-align: center;
}
.en-title{
  font-size: 20px;
  text-align: center;
  line-height: 24px;
  margin-bottom: 40px;
}
.contact-text{
  margin: 0 auto;
  line-height: 30px;
  margin-bottom: 30px;
}
.strategy-item{
  text-align: center;
  width: 33%;
  img{
    width: 80px;
  }

  .strategy-item-title{
    font-size: 14px;
    color: #333333;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .strategy-item-text{
    font-size: 16px;
    color: #999999;
  }
}
.qrcode-wrapper{
  height: 300px;
  overflow: hidden;
  margin-top: 40px;

  .sub-banner{
    width: 60%;
    img{
      width: 100%;
    }
  }
}
.qrcode-box{
  width: 40%;
  height: 300px;
  background: #f8f9fa;

  img{
    width: 230px;
  }
}
.qrcode-box{
  padding-top: 20px;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
}
.address-box{
  margin-top: 30px;
}
</style>